@charset "utf-8";
@import 'var';

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Foundation
ブラウザ仕様をフラットにする

文字色 : palette('color');
リンク : $link_color
ホバー : $hover_color

*/

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
}

li {
	list-style: none;
}
input,

textarea {
	outline: 0;
}

input::-moz-placeholder {
	color: palette('color', 'placeholder');
	opacity: .75;
}
input::-webkit-input-placeholder {
	color: palette('color', 'placeholder');
	opacity: .75;
}
input:-ms-input-placeholder {
	color: palette('color', 'placeholder');
	opacity: .75;
}

.none {
	display: none;
}

body {
	@include default_font-family;
	font-size: $fontsize16;
	line-height: 1.7;
	background: #fff;
	color: palette('color');
}
.gmap {
	// -webkit-mask-image: -webkit-radial-gradient(circle, #fff, #000);
	background-color: #eee;
	max-width: 100%;

	> iframe {
		max-width: 100%;
	}
	> img {
		max-width: 100%;
	}
}
.gcalendar {
	max-width: 100%;

	> iframe {
		max-width: 100%;
		// width: 100%; /* いれると iOS でおかしい */
	}
	> img {
		max-width: 100%;
	}
}
.twitter {
	overflow: hidden;
	> iframe {
		// width: 100% !important;
		margin-left: auto;
		margin-right: auto;
	}
}
.img,
%img {
	max-width: 100%;
}
img.border {
	border: 1px solid palette('gray');
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。  
このサイト専用というわけでもない共通設定。

*/

/*

<div class="_h3">.bold</div>

太字。

	<span class="bold">太い文字</span><br>
	<span class="em">太い文字</span>

*/
em,
.bold,
.em {
	font-weight: bold;
	font-style: normal;
}

/*

<div class="_h3">.underline</div>

下線。

	<span class="underline">テキスト</span><br>

*/
.underline {
	text-decoration: underline;
}


/*

<div class="_h3">.red .caution</div>

赤文字。

	<span class="caution">赤い文字</span><br>
	<span class="red">赤い文字</span>

*/
.caution,
.red_text {
	color: palette('red', 'alert');
	text-decoration-color: palette('red', 'alert');
}


/*

<div class="_h3">.orange_text</div>

オレンジ文字。

	<span class="orange_text">オレンジ文字</span>

*/
.orange_text {
	color: palette('orange');
}

.orange_text2 {
	color: palette('pink');
}

/*

<div class="_h3">.white_text</div>

白文字。

	<span class="white_text">オレンジ文字</span>

*/
.white_text {
	color: #fff;
}

/*

<div class="_h3">.blue_text</div>

時々使う青文字

	<div class="blue_text">テキスト</div>

*/
.blue_text {
	color: palette('blue');
	font-weight: bold;
}

.small_text {
	font-size: $fontsize12;
}

/*

<div class="_h3">.yellow_text</div>

時々使う青文字

	<div class="yellow_text">テキスト</div>

*/
.yellow_text {
	color: #a67f07;
	font-weight: bold;
}

/*

<div class="_h3">.gray_text</div>

時々使う灰色文字

	<div class="gray_text">テキスト</div>

*/
.gray_text {
	color: #888;
}

/*

<div class="_h3">.pink_text</div>

ピンク文字

	<div class="pink_text">テキスト</div>

*/
.pink_text {
	color: #ff7bac;
}

/*

<div class="_h3">.center</div>

適当に中央によせたい。

3つ指定してます  
margin-left: auto;  
margin-right: auto;  
text-align: center;  

	<div class="center">テキスト</div>

*/
.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}


/*

<div class="_h3">.center_position</div>

画像を適当に中央によせたい。  
親要素に relative が必要  
width と height は個別に指定した方がいい

	<div class='line'>
		<img class="center_position" src="http://placehold.it/100x100" alt="">
	</div>

*/
.center_position {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/*

<div class="_h3">.center_text</div>

text-align: right 。 float はしない。

	<div class="center_text">テキスト</div>

*/
.center_text {
	text-align: center;
}


/*

<div class="_h3">.right_text</div>

text-align: right 。 float はしない。

	<div class="right_text">テキスト</div>

*/
.right_text {
	text-align: right;
}


.float_right {
	float: right;
	padding-left: .5em;
	padding-bottom: .5em;
}

.float_left {
	float: left;
	padding-right: .5em;
	padding-bottom: .5em;
}

.clear_both {
	clear: both;
}


/*

<div class="_h3">.clearfix</div>

	<div class="clearfix">テキスト</div>

*/
.clearfix::after {
	content: '';
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}
.clearfix {display: inline-block;}
* html .clearfix {height: 1%;}
.clearfix {display: block;}


/*

<div class="_h3">.clear</div>

clear: both; だけしたい時。

	<div class="clear">テキスト</div>

*/
.clear {
	clear: both;
}

/*

<div class="_h3">.no_text</div>

背景画像だけを表示して、テキストを吹き飛ばして非表示にしたい。 text-indent: 120%;  
(下記例の info_title _hosp クラスはサンプルをわかりやすくするための指定。)

	<div class="info_title _hosp no_text">テキスト</div>

.no_text {
	text-indent: 120%;
	line-height: 0;
	white-space: nowrap;
	overflow: hidden;
	display: block;
}
*/
.no_text,
.hide-text {
	@include hide-text;
}


/*

<div class="_h3">.on_opacity</div>

:hover で透明化する aタグ。 opacity はサイト統一と考えて、基本ここにしか書いていません。

	<a href="#" class="on_opacity">マウスオーバーで透明化するリンクテキスト</a>

*/
.on_opacity {
	@include on_opacity;
}
/*

<div class="_h3">.ellipsis</div>

領域からはみ出た場合に「...」を表示する。ブラウザの挙動がおかしいのであまりにあてにしない。

	<div class="ellipsis" style="width: 100px;">長いテキスト長いテキスト長いテキスト長いテキスト</div>

*/
.ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

/*

<div class="_h3">.no_wrap</div>

改行せず、領域を貫通するテキスト。 PCのために書く時、SPで改行をせず表示が壊れないかに注意。

	<div class="no_wrap">テキスト</div>

*/
.no_wrap {
	white-space: nowrap;
}

.inline {
	display: inline;
}

/*

<div class="_h3">.mb_1em</div>

*/

.root .main_content {
	.mb_2em {
		margin-bottom: 2em;
	}
	.mb_1_5em {
		margin-bottom: 1.5em;
	}
	.mb_1em {
		margin-bottom: 1em;
	}
	.mb_05em {
		margin-bottom: .5em;
	}

	.mb_0em,
	.no_mb {
		margin-bottom: 0;
	}
}


/*

<div class="_h3">.tel</div>

comment

	<div class="fot_m">外来診療</div>

*/

.tel_link {
	word-wrap: break-word;
}

.mail_link {
	word-wrap: break-word;
	color: palette('link', 'mail_link');
}


.mincho {
	@include mincho_yu;
}

/**************************
.image_radius
*/

.image_radius {
	border-radius: $border_radius;
}

.no_radius {
	.root & {
		border-radius: inherit;
	}
}


/**************************
.skip
*/
.skip {
	border-style: none;
	height: 0;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 0;
	z-index: 100;
}

.fb_page_container {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}
