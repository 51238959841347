@charset "utf-8";

@keyframes gnavi_sub {
	0% {
		opacity: 0;
		transform: scale(.9) translateY(-15px);
	}
	100% {
		transform: scale(1) translateY(0);
	}
}

@keyframes ease_out_back {
	0% {
		transform: scale(.3);
	}
	60% {
		transform: scale(1.3);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes _on_opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes on_opacity {
	0% {
		opacity: 0;
		display: none;
	}
	100% {
		opacity: 1;
	}
}
@keyframes hero_area {
	0% {
		opacity: 0;
		display: none;
		transform: scale(.8);
	}
	20% {
		transform: scale(1);
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes pop-upwards {
	0% {
		transform: matrix(.97, 0, 0, 1, 0, 12);
		opacity: 0;
	}
	20% {
		transform: matrix(.99, 0, 0, 1, 0, 2);
		opacity: .7;
	}
	40% {
		transform: matrix(1, 0, 0, 1, 0, -1);
		opacity: 1;
	}
	70% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}
	100% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}
}

@keyframes pop-downwards {
	0% {
		transform: matrix(.97, 0, 0, 1, 0, -12);
		opacity: 0;
	}
	20% {
		transform: matrix(.99, 0, 0, 1, 0, -2);
		opacity: .7;
	}
	40% {
		transform: matrix(1, 0, 0, 1, 0, 1);
		opacity: 1;
	}
	70% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}
	100% {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}
}

@keyframes card {
	0% {
		transform: rotateX(-90deg) rotateY(-20deg) rotateZ(10deg) scale(.5) translateY(200px);
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

