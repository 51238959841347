@charset "utf-8";

@mixin btn($color_set: 'default', $width: 90px, $height: 30px) {
	@include _btn($width, $height, $color: #42210b, $bg: #ede2b2, $hover: #fff, $hover_bg: #ff8b00);
	// @if $color_set == 'blue' {
	// 	@include _btn($width, $height, $color: #000, $bg: #f2f8fc, $hover: #000, $hover_bg: darken(#f2f8fc, 5%));
	// } @else {
	// }
}

@mixin _btn($width, $height, $color, $bg, $hover, $hover_bg) {
	// @include icon-sprite2-inline($icon-arrow-right-orange, .2ex);
	display: inline-block;
	min-width: $width;
	min-height: $height;
	color: $color;
	background-color: $bg;
	position: relative;
	top: 0;
	left: 0;
	text-align: center;
	font-weight: bold;
	line-height: 1.3;
	box-sizing: border-box;
	font-size: $fontsize16;
	transition: background-color .1s ease-in-out;
	padding: .9ex 1em .8ex;
	border-radius: 5px;

	.main_content & {
		text-decoration: none;
	}

	&:hover {
		color: $hover;
		background-color: $hover_bg;

		&::before {
			// @include sprite2($icon-arrow-right-white);
		}
	}
}

