// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-arrow-right-brown-name: 'icon-arrow-right-brown';
$icon-arrow-right-brown-x: 0px;
$icon-arrow-right-brown-y: 0px;
$icon-arrow-right-brown-offset-x: 0px;
$icon-arrow-right-brown-offset-y: 0px;
$icon-arrow-right-brown-width: 10px;
$icon-arrow-right-brown-height: 16px;
$icon-arrow-right-brown-total-width: 100px;
$icon-arrow-right-brown-total-height: 444px;
$icon-arrow-right-brown-image: '/common/img/sprite-icon.png';
$icon-arrow-right-brown: (0px, 0px, 0px, 0px, 10px, 16px, 100px, 444px, '/common/img/sprite-icon.png', 'icon-arrow-right-brown', );
$icon-arrow-right-green-name: 'icon-arrow-right-green';
$icon-arrow-right-green-x: 0px;
$icon-arrow-right-green-y: 106px;
$icon-arrow-right-green-offset-x: 0px;
$icon-arrow-right-green-offset-y: -106px;
$icon-arrow-right-green-width: 16px;
$icon-arrow-right-green-height: 20px;
$icon-arrow-right-green-total-width: 100px;
$icon-arrow-right-green-total-height: 444px;
$icon-arrow-right-green-image: '/common/img/sprite-icon.png';
$icon-arrow-right-green: (0px, 106px, 0px, -106px, 16px, 20px, 100px, 444px, '/common/img/sprite-icon.png', 'icon-arrow-right-green', );
$icon-arrow-right-green2-name: 'icon-arrow-right-green2';
$icon-arrow-right-green2-x: 0px;
$icon-arrow-right-green2-y: 26px;
$icon-arrow-right-green2-offset-x: 0px;
$icon-arrow-right-green2-offset-y: -26px;
$icon-arrow-right-green2-width: 10px;
$icon-arrow-right-green2-height: 16px;
$icon-arrow-right-green2-total-width: 100px;
$icon-arrow-right-green2-total-height: 444px;
$icon-arrow-right-green2-image: '/common/img/sprite-icon.png';
$icon-arrow-right-green2: (0px, 26px, 0px, -26px, 10px, 16px, 100px, 444px, '/common/img/sprite-icon.png', 'icon-arrow-right-green2', );
$icon-arrow-right-orange-name: 'icon-arrow-right-orange';
$icon-arrow-right-orange-x: 0px;
$icon-arrow-right-orange-y: 78px;
$icon-arrow-right-orange-offset-x: 0px;
$icon-arrow-right-orange-offset-y: -78px;
$icon-arrow-right-orange-width: 14px;
$icon-arrow-right-orange-height: 18px;
$icon-arrow-right-orange-total-width: 100px;
$icon-arrow-right-orange-total-height: 444px;
$icon-arrow-right-orange-image: '/common/img/sprite-icon.png';
$icon-arrow-right-orange: (0px, 78px, 0px, -78px, 14px, 18px, 100px, 444px, '/common/img/sprite-icon.png', 'icon-arrow-right-orange', );
$icon-calendar-name: 'icon-calendar';
$icon-calendar-x: 0px;
$icon-calendar-y: 202px;
$icon-calendar-offset-x: 0px;
$icon-calendar-offset-y: -202px;
$icon-calendar-width: 32px;
$icon-calendar-height: 32px;
$icon-calendar-total-width: 100px;
$icon-calendar-total-height: 444px;
$icon-calendar-image: '/common/img/sprite-icon.png';
$icon-calendar: (0px, 202px, 0px, -202px, 32px, 32px, 100px, 444px, '/common/img/sprite-icon.png', 'icon-calendar', );
$icon-circle-green-name: 'icon-circle-green';
$icon-circle-green-x: 0px;
$icon-circle-green-y: 168px;
$icon-circle-green-offset-x: 0px;
$icon-circle-green-offset-y: -168px;
$icon-circle-green-width: 24px;
$icon-circle-green-height: 24px;
$icon-circle-green-total-width: 100px;
$icon-circle-green-total-height: 444px;
$icon-circle-green-image: '/common/img/sprite-icon.png';
$icon-circle-green: (0px, 168px, 0px, -168px, 24px, 24px, 100px, 444px, '/common/img/sprite-icon.png', 'icon-circle-green', );
$icon-open-menu-name: 'icon-open-menu';
$icon-open-menu-x: 0px;
$icon-open-menu-y: 344px;
$icon-open-menu-offset-x: 0px;
$icon-open-menu-offset-y: -344px;
$icon-open-menu-width: 100px;
$icon-open-menu-height: 100px;
$icon-open-menu-total-width: 100px;
$icon-open-menu-total-height: 444px;
$icon-open-menu-image: '/common/img/sprite-icon.png';
$icon-open-menu: (0px, 344px, 0px, -344px, 100px, 100px, 100px, 444px, '/common/img/sprite-icon.png', 'icon-open-menu', );
$icon-pdf-name: 'icon-pdf';
$icon-pdf-x: 0px;
$icon-pdf-y: 244px;
$icon-pdf-offset-x: 0px;
$icon-pdf-offset-y: -244px;
$icon-pdf-width: 28px;
$icon-pdf-height: 36px;
$icon-pdf-total-width: 100px;
$icon-pdf-total-height: 444px;
$icon-pdf-image: '/common/img/sprite-icon.png';
$icon-pdf: (0px, 244px, 0px, -244px, 28px, 36px, 100px, 444px, '/common/img/sprite-icon.png', 'icon-pdf', );
$icon-slick-dot-hover-name: 'icon-slick-dot-hover';
$icon-slick-dot-hover-x: 0px;
$icon-slick-dot-hover-y: 136px;
$icon-slick-dot-hover-offset-x: 0px;
$icon-slick-dot-hover-offset-y: -136px;
$icon-slick-dot-hover-width: 22px;
$icon-slick-dot-hover-height: 22px;
$icon-slick-dot-hover-total-width: 100px;
$icon-slick-dot-hover-total-height: 444px;
$icon-slick-dot-hover-image: '/common/img/sprite-icon.png';
$icon-slick-dot-hover: (0px, 136px, 0px, -136px, 22px, 22px, 100px, 444px, '/common/img/sprite-icon.png', 'icon-slick-dot-hover', );
$icon-slick-dot-name: 'icon-slick-dot';
$icon-slick-dot-x: 0px;
$icon-slick-dot-y: 52px;
$icon-slick-dot-offset-x: 0px;
$icon-slick-dot-offset-y: -52px;
$icon-slick-dot-width: 16px;
$icon-slick-dot-height: 16px;
$icon-slick-dot-total-width: 100px;
$icon-slick-dot-total-height: 444px;
$icon-slick-dot-image: '/common/img/sprite-icon.png';
$icon-slick-dot: (0px, 52px, 0px, -52px, 16px, 16px, 100px, 444px, '/common/img/sprite-icon.png', 'icon-slick-dot', );
$icon-tel-name: 'icon-tel';
$icon-tel-x: 0px;
$icon-tel-y: 290px;
$icon-tel-offset-x: 0px;
$icon-tel-offset-y: -290px;
$icon-tel-width: 44px;
$icon-tel-height: 44px;
$icon-tel-total-width: 100px;
$icon-tel-total-height: 444px;
$icon-tel-image: '/common/img/sprite-icon.png';
$icon-tel: (0px, 290px, 0px, -290px, 44px, 44px, 100px, 444px, '/common/img/sprite-icon.png', 'icon-tel', );
$spritesheet-width: 100px;
$spritesheet-height: 444px;
$spritesheet-image: '/common/img/sprite-icon.png';
$spritesheet-sprites: ($icon-arrow-right-brown, $icon-arrow-right-green, $icon-arrow-right-green2, $icon-arrow-right-orange, $icon-calendar, $icon-circle-green, $icon-open-menu, $icon-pdf, $icon-slick-dot-hover, $icon-slick-dot, $icon-tel, );
$spritesheet: (100px, 444px, '/common/img/sprite-icon.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
