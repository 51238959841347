@charset "utf-8";
@import 'lib/normalize';
@import 'import/var';
@import 'import/utility';
@import 'import/component';
@import 'import/animation';
@import 'sprite/icon';


/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/

body {
	background-color: palette('background', 'default');
}

.header {
	background-color: palette('background', 'default');
	z-index: 10000;
	position: fixed;
	top: 0;
	width: 100%;
	box-sizing: border-box;

	&__logo {
		@include on_opacity;
		a,
		img {
			display: block;
			max-width: 100%;
		}
	}
}

.form_btn {
	background-color: #fff;
	width: 190px;
	height: 30px;
	border: 1px solid #c6bcb6;
	border-radius: 3px;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	font-size: $fontsize13;
	line-height: 30px;
	box-sizing: border-box;
	color: palette('brown', 'default');
	transition: all .1s ease-in-out;

	&::before {
		@include sprite2($icon-arrow-right-green);
		content: '';
		display: inline-block;
		margin-right: .5ex;
		position: relative;
		top: 1px;
		left: 0;
	}
	&::after {
		@include sprite2($icon-calendar);
		content: '';
		display: inline-block;
		margin-left: .8ex;
		position: relative;
		top: 3px;
		left: 0;
	}
	&:hover {
		background-color: darken(#c6bcb6, 40%);
		color: #fff;
	}
}

.footer {
	position: relative;
	top: 0;
	left: 0;
	border-top: 3px solid palette('green', '2');
	padding-bottom: 30px;
	&__logo {
		@include on_opacity;
		img {
			display: block;
			max-width: 100%;
		}
	}
	&__tel {
		display: block;
		max-width: 213px;
		 img {
		 	display: block;
		 	max-width: 100%;
		 }
	}
	&__schedule {
		&__head {
			margin-top: 2px;
			margin-bottom: 3px;
			.main {
				font-weight: bold;
				margin-right: .5em;
			}
		}
	}
	&__copyright {
		text-align: center;
		font-size: $fontsize11;
		color: palette('brown', 'default');
	}
}

.cate_area {
	width: 100%;
	overflow: hidden;
	box-sizing: border-box;
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: palette('yellow', 'default');
	position: relative;
	top: 0;
	left: 0;

	&__text {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		margin-top: -15px;
	}

	h2 {
		margin-bottom: 5px;
		img {
			display: block;
			margin: auto;
		}
	}
	.sub {
		text-align: center;
		color: palette('brown', '2');
		font-size: $fontsize14;
	}
}

.circle_title {
	@include icon-sprite2($icon-circle-green, .1em, 'before', false, .1em);
	font-weight: bold;
	margin-bottom: .3em;
}

.pagelink_box {
	background-color: #fff;
	border: 5px solid #f2f2f2;
	&__body {
		margin-bottom: 6px;
	}
	&__foot {
		margin-top: 14px;
		margin-bottom: 15px;
	}

	.link {
		border-bottom: 1px solid #ccc;
		display: block;
		padding-top: 9px;
		padding-bottom: 8px;
		background-color: #fff;
		min-height: 40px;
		box-sizing: border-box;
		transition: background .2s ease-in-out;
		padding-left: 15px;
		&::before {
			transition: top .2s ease-in-out;
			position: absolute;
			top: 16px;
			left: 4px;
		}

		&:hover {
			background-color: #f7f7f7;
			&::before {
				top: 18px;
			}
		}
	}
}

.iclinic_wrapper {
	margin-top: 100px;
	margin-bottom: 60px;
}

.iclinic_list {
	a,
	img {
		display: block;
	}
	a {
		@include on_opacity;
	}
	@media only screen and (min-width: 768px) {
		li {
			padding-bottom: 20px;
		}
		&-4 {
			@include columns(4, 20px);
		}
		&-2 {
			@include columns(2, 20px);
		}
	}
	@media only screen and (max-width: 767px) {
		li {
			padding-bottom: 10px;
		}
		&-4 {
			@include columns(2, 10px);
		}
	}
}



.main_content {
	h3:not(.cancel) {
		font-size: $fontsize24;
		color: #fff;
		font-weight: bold;
		background-color: #8bc750;
		border-radius: 5px;
		min-height: 50px;
		line-height: 1.3;
		padding: .8ex 1ex .6ex;
		box-sizing: border-box;
		margin-bottom: .7em;
	}
	h4:not(.cancel) {
		font-size: $fontsize20;
		border-left: 4px solid palette('green', '5');
		padding-left: 15px;
		line-height: 1.3;
		padding-top: .2em;
		padding-bottom: .2em;
		margin-bottom: 1em;
	}
}

.labeltext {
	min-width: 36px;
	min-height: 18px;
	text-align: center;
	padding: .1ex 1.2ex .2ex;
	line-height: 1;
	color: #fff;
	font-size: $fontsize12;
	border-radius: 5px;
	position: relative;
	top: -.4ex;
	left: 0;

	margin-left: 1.3em;

	& + & {
		margin-left: .6ex;
	}

	&-male {
		background-color: #39a2c6;
	}
	&-female {
		background-color: #f15a24;
	}
}


