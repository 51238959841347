@charset "utf-8";
@import 'var';
@import 'sprite/icon';

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。


*/

/**************************
heading
*/


/*

<div class="_h3">.large_text</div>

大きい文字。

	<span class="large_text">テキスト</span><br>

*/
.large_text {
	@include fot_l('M');
	font-size: $fontsize20;
	font-weight: normal;
	line-height: 2;
}

.large_text.red_text {
	color: palette('red');
}

.push_text {
	font-size: $fontsize20;
	font-weight: bold;
	color: palette('pink');
	line-height: 1.5;

	&-blue {
		color: palette('blue');
	}
}


/**************************
.main_content
*/

.main_content {
	$list_padding_left: 1.5em;
	$list_margin_bottom: .5em;

	ul:not(.cancel) {
		&:not(:last-child) {
			margin-bottom: 1em;
		}

		> li {
			line-height: 1.6;
			padding-left: $list_padding_left;
			position: relative;
			top: 0;
			left: 0;

			&:not(:last-child) {
				margin-bottom: $list_margin_bottom;
			}
			&::before {
				// @include sprite2($icon-disc-orange);
				content: '';
				// color: palette('orange', '1')
				display: block;
				position: absolute;
				top: 1.1ex;
				left: .4ex;
			}

		}
	}
	ol:not(.cancel) {
		list-style: none;
		counter-reset: num;

		&:not(:last-child) {
			margin-bottom: 1em;
		}

		> li {
			padding-left: $list_padding_left;
			position: relative;
			top: 0;
			left: 0;

			&:not(:last-child) {
				margin-bottom: $list_margin_bottom;
			}
			&::before {
				counter-increment: num;
				content: counter(num)'.';
				// font-weight: bold;
				// margin-left: -1.75em;
				color: palette('orange', '1');
				position: absolute;
				top: 0;
				left: ($list_padding_left * -1 / 3);
				text-align: right;
				width: $list_padding_left;
			}
		}
	}
	.ol-i:not(.cancel) {
		> li {
			&::before {
				content: counter(num, upper-roman)'. ';
				text-align: center;
			}
		}
	}
}

.table,
.table-borderd,
.wpnews table {
	$padding: .5ex 1ex;
	$th_width: 310px;
	background-color: #fff;

	width: 100%;
	border-collapse: collapse;
	&:not(:last-child) {
		margin-bottom: 2em;
	}

	caption {
		display: none;
		&.cap {
			display: block;
			text-align: left;
			font-weight: bold;
			color: palette('orange');
			padding-bottom: .5em;
			// padding-left: .7em;
		}
	}

	th,
	td {
		vertical-align: top;
		font-weight: normal;
		border: 1px solid palette('table', 'border');
		padding: $padding;
		box-sizing: border-box;
	}
	th {
		width: $th_width;
		background-color: palette('table', 'bg');
		color: palette('color');
		font-weight: bold;
		text-align: left;
		vertical-align: middle;

	}
	+ .right_text {
		margin-top: -1.5em;
	}
}

.table {
	&-1 {
		thead {
			th {
				font-weight: normal;
				text-align: center;
			}
		}
		th {
			padding: .7em;
			height: 40px;
		}
		td {
			padding: .3em 1ex;
			vertical-align: middle;
			height: 60px;
		}
		.cap {
			width: 100px;
			img {
				width: 45px;
				display: block;
				margin: auto;
			}
		}
		.name {
			width: 430px;
		}
		.unit {
			width: 100px;
		}
		.price {
			width: 210px;
		}
		.kensa {
			width: 530px;
		}
		td.unit {
			text-align: center;
		}
		td.price {
			text-align: right;
		}
	}

	&-2 {
		thead {
			th {
				font-weight: normal;
				text-align: center;
			}
		}
		th {
			padding: .7em;
			height: 40px;
		}
		td.unit {
			text-align: center;
		}
		td.price {
			text-align: right;
		}
	}
	&-price {
		td {
			text-align: right;
			line-height: 1.5;
		}
	}
	&-noborder {
		th,
		td {
			border: 0 none;
			background-color: transparent;
			text-align: left;
			vertical-align: top;
		}
		th {
			padding-right: 2em;
		}
	}
}
.link_list {
	padding-left: 0;
	li {
		margin-bottom: 1em;

		&::before {
			display: none;
		}
	}
	a {
		display: inline-block;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

.link_list2 {
	padding-left: 0;
	li {
		margin-bottom: .7em;

		&::before {
			display: none;
		}
	}
	a {
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}
.banner_horizontal_list {
	padding-left: 0;
	> li {
		&::before {
			display: none;
		}
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
}

%anotation,
.anotation {
	position: relative;
	top: 0;
	left: 0;
	color: palette('red', 'default');
	padding-left: 1em;

	&::before {
		content: '※';
		width: 1em;
		position: absolute;
		top: .04em;
		left: 0;
		color: palette('red', 'default');
	}

	&-small {
		font-size: $fontsize12;
		line-height: 1.2;

		&::before {
			font-size: $fontsize12;
		}
	}
	&-red {
		&::before {
			color: palette('red');
		}
	}
	&-right_text {
		text-align: right;
		display: inline-block;
	}

}

.anotation_list {
	&:not(:last-child) {
		margin-bottom: 1em;
	}
	li {
		font-size: $fontsize14;
		margin-bottom: .3em;
		position: relative;
		top: 0;
		left: 0;
		&.red_text {
			&::before {
				color: palette('red', 'default');
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
		&::before {
			content: '※';
			color: palette('red', 'default');
			font-size: $fontsize14;
			width: 1em;
			display: block;
			position: absolute;
			top: .3em;
			left: 0;
			margin-left: 0;
			line-height: 1;
		}
	}
	&-red {
		li::before {
			color: palette('red');
		}
	}
	&-sm {
		li {
			color: #888;
			font-size: $fontsize13;
			margin-bottom: .1em;
			&::before {
				color: #888;
				font-size: $fontsize13;
			}
		}
	}
}

/**************************
.content_section
*/

%section,
.content_section {
	margin-bottom: 3em;
	&-lg {
		&:not(:last-child) {
			margin-bottom: 6em;
		}
	}
	&-sm {
		text-align: center;
		&:not(:last-child) {
			margin-bottom: 35px;
		}
	}

	&-sm2 {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
	&-xs {
		&:not(:last-child) {
			padding-bottom: 1em;
		}
	}
	&-nomb {
		margin-bottom: 0;
	}
}

/**************************

## - p

*/

/*

<div class="_h3">p</div>

*/

section {
	p {
		&:not(:last-child) {
			margin-bottom: 1em;
		}
	}
}

p {
	font-size: $fontsize15;
	line-height: (24 / 14);
	color: #000;
	margin-bottom: 1em;
}

/**************************

## - a リンク設定

*/

/*

<div class="_h3">a</div>

基本のリンク設定

	<a href="#" class="a">テキスト</a>

*/

a {
	color: palette('link');
	text-decoration: none;
	&:hover,
	&:hover .link_arrow {
		color: palette('link', 'hover');
		// text-decoration: underline;
	}
}

.image_banner {
	@include on_opacity;
	display: block;
	border: 1px solid palette('gray');
	padding: 1px;
	> img {
		display: block;
		max-width: 100%;
	}
}

.image_round {
	border-radius: 5px;
}

.url {
	text-decoration: underline;
	color: palette('link');
	word-break: break-all;

	&:hover,
	&:focus,
	&:active {
		color: palette('link');
		text-decoration: none;
	}
}

input,
textarea {
	&:focus,
	&:active {
		/*text-decoration: underline;*/
		outline: 2px solid palette('color', 'outline');
		// box-shadow: 0 0 1px 2px palette('color', 'outline');
		// outline: 0 none;
	}
}
/*

<div class="_h3">a.black</div>

リンクだけど文字色は黒

	<a href="#" class="black">テキスト</a>

*/
a.black,
a .black {
	color: palette('link', 'black');
}


/*

<div class="_h3">.outlink</div>

外部リンク。後ろに矢印画像を表示する

	<a href="#" class="outlink">テキスト</a>

*/
.out,
.ext,
.outlink,
.icon_out,
.icon_ext {
	// // @include icon-sprite2($icon-ext, .15em, after, false, .3em);
}


/**************************

## - .btn - 汎用ボタン

*/

/*

<div class="_h3">.btn</div>

汎用ボタン。

*/

.btn {
	@include icon-sprite2($icon-arrow-right-brown, -.05em, before, false, .3em);
	@include btn('default', auto, 40px);

	&.ext {
		&::after {
			// // @include sprite2($icon-ext-white);
		}
	}
	+ .btn {
		margin-left: 1em;
	}
}


btn.btn,
input.btn {

	&-large {
		padding-top: 13px;
	}
	&-disabled {
		.root & {
			// pointer-events: none;
			opacity: .4;

			&:hover {
				opacity: .4;
			}
		}
	}
}

.submit_prev {
	@include on_opacity;
	color: palette('green');
	border: 0 none;
	margin: 0;
	padding: 0;
	cursor: pointer;
	background: url('../img/icon_arrow_left_green.png') left center no-repeat transparent;
	background-size: auto 10px;
	padding-left: 10px;
}
.submit_next {
	@include on_opacity;
	color: palette('green');
	border: 0 none;
	margin: 0;
	padding: 0;
	cursor: pointer;
	background: url('../img/icon_arrow_right_green.png') right center no-repeat transparent;
	background-size: auto 10px;
	padding-right: 10px;
}

/*

<div class="_h3">.icon_menu</div>

メニューアイコン

	<a href="#" class="btn _large __orange fot_db">
		<span class="icon_menu">テキスト</span>
	</a>

*/
.icon_menu {
	display: inline-block;
	min-height: 14px;
	background: url( '../img/icon_menu.png' ) 0 center no-repeat transparent;
	background-size: 16px 14px;
	padding-left: 23px;
	line-height: 16px;
}
/*

<div class="_h3">.icon_pdf</div>

PDFアイコン

	<span class="icon_pdf">テキスト</span><br>
	<span class="icon_pdf_right">テキスト</span><br>
	<a href="#" class="btn _large __orange fot_db">
		<span class="icon_pdf2">テキスト</span>
	</a>

*/

.pdf,
.icon_pdf {
	@include icon-sprite2($icon-pdf, $top: .3ex, $before: after, $is_block: false, $margin-right: .5em);
}


// .icon_word {
// 	&::after {
// 		@include icon('../img/icon_word.png', 13px, 16px, 2px);
// 		margin-left: 4px;
// 	}
// }

// .icon_excel {
// 	&::after {
// 		@include icon('../img/icon_excel.png', 13px, 16px, 2px);
// 		margin-left: 4px;
// 	}
// }

// .icon_mail-en {
// 	color: palette('color');
// 	font-size: $fontsize13;
// 	&::before {
// 		@include icon('../img/icon_mail-en.png', 14px, 11px, .1em);
// 		margin-right: .2em;
// 	}
// }

/*

## - list ul ol

<div class="_h3">.anotation_list</div>

※ をつけるリスト。
改行時に ※ padding を守る。

	<ul class="anotation_list">
		<li>16歳（高校生）以上の方の<br>診察となります。</li>
		<li>月2回の診療のため、お電話で診療日をご確認下さい。</li>
	</ul>

*/


.dl {
	&::before,
	&::after {
		content: '';
		display: table;
	}
	&::after {
		clear: both;
	}
	dt {
		font-weight: bold;
		color: palette('green', 'default');
		position: relative;
		top: 0;
		left: 0;
		z-index: 2;
		&::before {
			margin-right: .5em;
		}
	}

	dt,
	dd {
		&:last-child {
			margin-bottom: 0;
		}
	}

	&-mb_1em {
		dd {
			margin-bottom: 1em;
		}
	}
	&-dt_bold {
		dt {
			font-weight: bold;
		}
	}
}


.dl_list {
	dt {
		font-weight: bold;
	}
	dd {
		border-bottom: 1px dashed palette('gray');
		padding-bottom: .5em;
		margin-bottom: .5em;
	}
}
.scell {
	margin-right: 1em;
}
.scell2 {
	margin-right: .5em;
}



/*

<div class="_h3">.dot_list</div>

・ をつけるリスト。
改行時に ・ padding を守る。

	<ul class="dot_list">
		<li>16歳（高校生）以上の方の<br>診察となります。</li>
		<li>月2回の診療のため、お電話で診療日をご確認下さい。</li>
	</ul>

*/
.dot_list {
	margin-bottom: 1em;

	li {
		padding-left: 16px;
		margin-bottom: 0.3em;
	}
	li::before {
		content: '';
		display: inline-block;
		background: url( '../img/icon_dot_green.png' ) center center no-repeat transparent;
		background-size: 6px 6px;
		width: 6px;
		height: 6px;
		line-height: 6px;
		margin-left: -20px;
		padding-left: 20px;
		box-sizing: border-box;
		position: relative;
		top: -2px;
		left: 0;
	}
}

/**************************
.indent_list
*/
.indent_list li li {
	padding-left: 1.3em;
	font-size: 95%;
	line-height: 1.5;
}
.indent_list li ul {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}
/**************************
.map_list
*/

.map_list {
	a {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}

/**************************
.inline_list
*/

.inline_list {
	@include inline-list;
}

/**************************
.inline_block-list
*/

.inline_block_list {
	@include inline-block-list(5px);
}

/**************************
.horizontal_list
*/

.horizontal_list {
	@include horizontal-list(10px);

	.main_content & {
		padding-left: 0;
		li {
			&::before {
				display: none;
			}
		}
	}
}




/*

<div class="_h3">.breadcrumbs</div>

ぱんくずリスト

	<ul class="breadcrumbs">
		<li><a href="#" class="node">トップ</a></li>
		<li><a href="#" class="node">診療科・部門紹介</a></li>
		<li><a href="#" class="node">総合健診センター</a></li>
		<li><span class="node">予約から受診までの流れ</span></li>
	</ul>

*/

.breadcrumbs {
	text-align: left;
	box-sizing: border-box;
	line-height: 1;
	padding-top: 1.5em;
	padding-bottom: 1.5em;

	li {
		display: inline;
		font-size: $fontsize12;
		line-height: 1.2;

		// &:first-child .node {
			// // @include icon-sprite2($icon-bread-home, .15em);
		// 	padding-left: 20px;
		// 	margin-left: 0;
		// }
	}
	a.node {
		color: palette('link');
		font-weight: normal;
		position: relative;
		top: 0;
		left: 0;
		text-decoration: underline;

		&::after {
			// @include sprite2($icon-arrow-breadcrumbs);
			content: '';
			display: inline-block;
			margin-left: 1em;
			margin-right: 1em;
			position: relative;
			top: .28em;
			left: 0;
		}

		&:hover {
			text-decoration: underline;
			// color: palette('link', 'hover');
			color: #888;
			opacity: 1;
			// text-decoration: none;
		}
	}
	.node {
		margin-left: 0em;
		margin-right: 0em;
		font-size: $fontsize13;
		color: #808080;
	}
}


/*

## - table

<div class="_h3">クラス名なし</div>

	<table>
		<thead>
			<tr>
				<th></th>
				<th>詳細</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<th>事業所名所</th>
				<td>宗教法人寒川神社　寒川病院</td>
			</tr>
			<tr>
				<th>所在地</th>
				<td>〒253-0106　神奈川県高座郡寒川町宮山193</td>
			</tr>
			<tr>
				<th>開設</th>
				<td>昭和44年7月</td>
			</tr>
		</tbody>
	</table>

<div class="_h3">.table1</div>
.table1 は、SP表示時に display: block; に変わるので、基本、2列の table のための table

	<table class="table1">
		<tbody>
			<tr>
				<th>事業所名所</th>
				<td>宗教法人寒川神社　寒川病院</td>
			</tr>
			<tr>
				<th>所在地</th>
				<td>〒253-0106　神奈川県高座郡寒川町宮山193</td>
			</tr>
			<tr>
				<th>開設</th>
				<td>昭和44年7月</td>
			</tr>
		</tbody>
	</table>
*/
.wpnews table {
	margin-bottom: 1em;
}

/*

<div class="_h3">table._no_border</div>

画像だけの table を想定

*/


table {
	table._no_border {
		width: auto;
		margin: auto;
		th,
		td {
			padding: 0 1em;
			vertical-align: middle;
		}
	}
}


/**************************
.inline_video
*/
.inline_video {
	max-width: 100%;
}

/*

<div class="_h3">photoswipe ギャラリー</div>

pdf を iframe 表示に使用。
.photoswipe > .pdf または、
.photoswipe > ._pdf または、
.photoswipe > ._iframe の時、iframe を表示する仕様。

+ a タグの href が pdf への URL。
+ a タグの title が キャプションテキスト。

	<div class="photoswipe">
		<a href="pdf/test.pdf" target="_blank" class="pdf">貯金一覧</a>
	</div>

*/

.photoswipe {
	a {
		@include on_opacity;
	}
}
.pswp {
	.pswp__iframe {
		padding: 0;
		width: 80%;
		height: 90%;
		position: relative;
		top: 5%;
		left: 10%;
		box-sizing: border-box;
	}

	.pswp__caption__center {
		text-align: center;
		font-size: $fontsize16;
	}
}

/*
.scroll_to_top
*/
.scroll_to_top {
	@include on_opacity;
	position: fixed;
	// right: 30px;
	bottom: -1 * $scroll_to_top_height;
	z-index: 999;
	line-height: 0;

	img {
		width: $scroll_to_top_width;
		height: $scroll_to_top_height;
	}
}

/*
## - form input select textarea

<div class="_h3">input select textarea</div>

*/
.caution_area {
	border: 2px solid #fb911f;
	border-radius: $border_radius;
	color: #ff0000;
	padding: 5px 10px;
}
.caution_area p {
	margin-bottom: 1px;
}

.caution {
	color: palette('red', 'default');
}
.text_frame {
	$height: 50px;
	input {
		width: 100%;
		font-size: $fontsize15;
		box-sizing: border-box;
		margin: 0;
		// border: 0 none;
		background-color: #fff;
		border-radius: $border_radius;
		border: 1px solid #ccc;
		box-shadow: 0px 2px .2px .2px #ececec inset;

		height: $height;
		padding: 0 .7em;
		padding-right: .5em;

		&:focus,
		&:active {
			outline: 2px solid palette('color', 'outline');
			outline-offset: -2px;
		}
	}
	&-age {
		input {
			width: 7em;
			margin-right: 1em;
		}
	}
}
.form {
	&__text {
		border-radius: $border_radius;
		box-shadow: 0 1px 0 0 #cacaca inset;
		background-color: #fff;
	}
	&__submit {
		border: 1px solid #fff;
		background-color: palette('blue');
		font-weight: normal;
		&:hover {
			// background-color: palette('blue')_hover_bg;
		}
	}
	&-color {
		&__text {
			border: 0 none;
			color: palette('color');
		}

	}
}

.select_frame {
	$select_frame_height: 40px;
	position: relative;
	top: 0;
	left: 0;
	&__display {
		border: 1px solid #ccc;
		border-radius: $border_radius;
		background-color: #fff;

		font-size: $fontsize16;
		// color: palette('blue');
		z-index: 1;
		position: relative;
		top: 0;
		left: 0;
		box-sizing: border-box;
		padding-top: .4em;
		padding-left: .6em;
		height: $select_frame_height;
		padding-right: 26px;

		&::after {
			// @include icon_block('../img/icon_select_after.png', 7px, 16px);
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
		}
		&-default {
			color: #333;
		}
	}
	select {
		-webkit-user-modify: read-write-plaintext-only;
		-moz-user-modify: read-write-plaintext-only;
		box-sizing: border-box;
		padding: 0;
		margin: 0;
		border: 0 none;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		opacity: 0;
		width: 100%;
		height: $select_frame_height;
		cursor: pointer;

		&:focus + .select_frame__display,
		&:active + .select_frame__display {
			outline: 2px solid palette('color', 'outline');
			outline-offset: -2px;
		}
	}
	option {
		padding: 0 15px;
	}
}
.submit_frame input.search {
	background: url( '../img/icon_search_dark.png' ) 10px center no-repeat transparent;
	background-size: 13px 15px;
	padding-left: 28px;
}

.textarea_frame {
	textarea {
		width: 100%;
		font-size: $fontsize16;
		box-sizing: border-box;
		line-height: 1.5em;
		margin: 0;
		// background-color: #ececec;
		border-radius: $border_radius;
		border: 1px solid palette('table', 'border');
		box-shadow: 0px 2px .2px .2px #ececec inset;

		padding: .7em;
		height: 10em;
		resize: none;

		&:focus,
		&:active {
			outline: 2px solid palette('color', 'outline');
			outline-offset: -2px;
		}

	}
	&-m {
		textarea {
			height: 5em;
		}
	}
}

.radio_frame {
	position: relative;
	top: 0;
	left: 0;
	height: 100%;
	> label {
		display: table;
		width: 100%;
		height: 100%;
	}

	&__input {
		position: absolute;
		-webkit-user-modify: read-write-plaintext-only;
		-moz-user-modify: read-write-plaintext-only;
		z-index: 1;
		left: 0;
		width: 14px;
		opacity: 0;
		padding: 0;
		margin: 0;
		border: 0 none;

		&:focus + .radio_frame__label,
		&:active + .radio_frame__label {
			outline: 2px solid palette('color', 'outline');
			outline-offset: -2px;
		}
	}
	&__label {
		cursor: pointer;
		border: 1px solid #cfcfcf;
		border-radius: $border_radius;
		color: #888;
		text-align: center;
		font-size: $fontsize15;
	}
	.radio_frame__input:checked + .radio_frame__label {
		color: #fff;
		background-color: palette('red', '3');
	}
}

.radio {
	&_list {
		display: table;
		width: 100%;

		.radio_frame {
			height: 100%;
			> label {
				display: table;
				width: 100%;
				height: 100%;
			}
		}
		.radio_frame__label {
			height: 100%;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			border-right-width: 0;
			vertical-align: middle;
			display: table-cell;
			width: 100%;
			background-color: #eee;
		}
	}
	&__li {
		display: table-cell;
		height: 50px;
		&:last-child .radio_frame__label {
			border-right-width: 1px;
		}
		&-2 {
			width: 50%;
			box-sizing: border-box;

		}
		&-3 {
			width: 33%;
			box-sizing: border-box;

		}
		&:first-child {
			.radio_frame__label {
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
			}
		}
		&:last-child {
			.radio_frame__label {
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px;
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
			}
		}
	}
}

.birth {
	position: relative;
	top: 0;
	left: 0;

	$unit_width: 3em;
	&__input {
		float: left;
		box-sizing: border-box;
		width: 100%;
		padding-right: $unit_width;
		margin-right: - 1 * $unit_width;
	}
	&__unit {
		float: left;
		width: $unit_width;
		text-align: center;
		position: relative;
		line-height: 3.5em;
	}

	&::before,
	&::after {
		content: '';
		display: table;
	}
	&::after {
		clear: both;
	}
	&_list {
		@include clearfix;
	}
}

.check_frame {
	$check_frame_width: 19px;
	$check_frame_height: 19px;
	$check_frame_top: .25em;

	position: relative;
	top: 0;
	left: 0;
	min-width: $check_frame_width;
	min-height: $check_frame_height;

	&-focus .check_frame__display {
		outline: 2px solid palette('color', 'outline');
	}
	&__icon {
		// // @include sprite2($icon-checkbox);
		// width: $check_frame_width;
		// height: $check_frame_height;
		// background-size: $check_frame_width $check_frame_height;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		// transform: scale(0);
		// transition: all .2s linear;
	}
	&__display {
		// // @include sprite2($icon-checkbox-off);
		display: block;
		// width: $check_frame_width;
		// height: $check_frame_height;
		// border: 1px solid palette('blue');
		// border-radius: 4px;
		// box-shadow: 0 1px 0 0 #bfbfbf inset;
		// background: url('../img/checkbox-off.png') 0 0 no-repeat transparent;
		// background-size: $check_frame_width $check_frame_height;
		box-sizing: border-box;
		position: absolute;
		left: 0;
	}
	&__input {
		cursor: pointer;
		position: absolute;
		-webkit-user-modify: read-write-plaintext-only;
		-moz-user-modify: read-write-plaintext-only;
		z-index: 1;
		left: 0;
		width: $check_frame_width;
		height: $check_frame_height;
		box-sizing: border-box;
		opacity: 0;
		padding: 0;
		margin: 0;
		border: 0 none;

		// &:active + .check_frame__display,
		&:focus + .check_frame__display {
			outline: 2px solid palette('color', 'outline');
			outline-offset: -2px;
		}
	}
	&__display,
	&__input {
		top: $check_frame_top;
	}
	&__label {
		margin-left: 24px;
		display: block;
	}
	&__input:checked + &__display &__icon {
		opacity: 1;
		transform: scale(1);
	}
}



/*

<div class="_h3">.notice</div>

警告のようなボックス表示

	<div class="notice">
		<div class="notice__text">当院は、紹介予約制を原則としています。</div>
	</div>

*/

// .notice {
// 	padding: 1em 1.5em;;
// 	// background-color: $pink_color;
// 	margin-bottom: 1em;

// 	&__text {
// 		color: palette('red');
// 		font-weight: bold;
// 		font-size: $fontsize16;

// 		&::before {
// 			@include icon('../img/icon_notice@2x.png', 15px, 15px, .1em);
// 			margin-right: .2em;
// 		}
// 	}
// }


// .image_line {
// 	margin-bottom: 1em;

// 	> li {
// 		display: inline-block;
// 		margin-right: .3em;


// 		&:last-child {
// 			margin-right: 0;
// 		}
// 	}

// 	&:last-child {
// 		margin-bottom: 0;
// 	}

// 	&--center {
// 		text-align: center;

// 		img {
// 			max-width: 100%;
// 		}
// 	}
// 	.caption {
// 		font-size: $fontsize12;
// 		line-height: 1.2;
// 	}
// }

/**************************
	.sup_area
*/

.sup_area {
	position: relative;
	top: 0;
	left: 0;

}
.sup {
	position: absolute;
	top: -.8em;;
	left: 0;
	width: 100%;
	font-size: $fontsize10;
	display: inline-block;
	text-align: center;
}

/*

<div class="_h3">.news</div>

*/
.news_list {
	&,
	> map {
		display: block;
		box-sizing: border-box;
		width: 100%;
		line-height: 1.5;
	}
}
.news {
	font-size: $fontsize14;
	line-height: 1.7;
	text-decoration: none;
	// flex-wrap: wrap;
	box-sizing: border-box;
	position: relative;
	top: 0;
	left: 0;
	border-bottom: 1px solid palette('green', '3');


	&:first-child {
		border-top: 1px solid palette('green', '3');
	}

	&:hover {
		background-color: rgba(#fff, .3);
	}
	&._disable {
		pointer-events: none;
		&::after {
			display: none;
		}
	}
	&__head {
		display: flex;
	}
	&__date {
		padding-right: .9em;
		box-sizing: border-box;
		color: #000;
		position: relative;
		top: 0;
		left: 0;

		&::before {
			@include sprite2($icon-arrow-right-green);
			content: '';
			display: block;
			position: absolute;
			top: 9px;
		}
	}
	&__cate {
		// padding-left: 1em;
		box-sizing: border-box;
		font-size: 0;
		line-height: 0;
	}

	&__label {
		display: inline-block;
		font-size: $fontsize12;
		background-color: palette('category', 'news');
		color: #fff;
		text-align: center;
		padding: .1em 1ex 0;
		position: relative;
		top: -.3ex;
		left: 0;
		height: 26px;
		line-height: 26px;
		border-radius: 3px;
		font-weight: bold;

		@each $cate, $value in map-get($palettes, 'category') {
			&-#{$cate} {
				background-color: $value;
			}
		}
	}

	&__ex {
		color: palette('green', 'default');
		// color: lighten(palette('green', 'default'), 10%);
	}
	// .new {
	// 	position: relative;
	// 	top: 3px;
	// 	left: 0;
	// }
}


/**************************
.accordion
*/

.accordion {
	.accordion__toggle {
		display: block;
	}
	.accordion__content {
		position: relative;
		top: 0;
		left: 0;
		max-height: 0;
		opacity: 0;
		transition: opacity .1s ease-out, max-height .4s ease-out;
		overflow: hidden;

		&__inner {
			padding-bottom: 2em;
		}
	}
	&.opened {
		.accordion__content {
			max-height: 100em;
			opacity: 1;
			&-long {
				max-height: 200em;
			}
			&-short {
				max-height: 20em;
			}
		}
	}
}

/**************************

<div class="_h3">split_table</div>

PC TB SP で、横スワイプ可能なテーブル

*/
// .split_table {
// 	margin-bottom: 0;

// 	&_area {
// 		position: relative;
// 		top: 0;
// 		left: 0;
// 		clear: both;
// 	}
// 	&_mask {
// 		width: 100%;
// 		height: 100%;
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		background: url( '../img/icon_swipe.png' ) center center no-repeat transparent;
// 		background-size: auto 90px;
// 		z-index: 102;
// 		cursor: pointer;
// 	}
// 	&_mask.shadow {
// 		background-image: none;
// 		background: #666;
// 		opacity: 0.5;
// 		z-index: 101;
// 	}
// }



// 	table.split_table { margin-bottom: 0; }

// 	div.table-wrapper thead td { width: auto; }

// 	.pinned { position: absolute; left: 0; top: 0; background: #fff; width: 35%; overflow: hidden; overflow-x: hidden; border-right: 1px solid #ccc; border-left: 1px solid #ccc; }
// 	.pinned table { border-right: none; border-left: none; width: 100%; }
// 	.pinned table th, .pinned table td { white-space: nowrap; }
// 	.pinned td:last-child { border-bottom: 0; }

// 	div.table-wrapper { position: relative; margin-bottom: 20px; overflow: hidden; border-right: 1px solid #ccc; }
// 	div.table-wrapper div.scrollable { margin-left: 35%; }
// 	div.table-wrapper div.scrollable { overflow: scroll; overflow-y: hidden; }

// 	table.split_table td, table.split_table th { white-space: nowrap; overflow: hidden; }
// 	table.split_table th:first-child, table.split_table td:first-child, table.split_table td:first-child, table.split_table.pinned td { display: none; }

// 	.table-wrapper .pinned {
// 		width: 111px;
// 		border-left: 0 none;
// 		border-right: 0 none;
// 		padding-bottom: 5px;
// 	}

// 	div.table-wrapper tr {
// 		box-sizing: border-box;
// 	}
// 	div.table-wrapper div.scrollable {
// 		padding-left: 110px;
// 		margin-left: 0;
// 		box-sizing: border-box;
// 		/*width: 700px;*/
// 	}

// .split_table_area {
// 	th:first-child {
// 		max-width: 200px;
// 		min-width: 200px;
// 		width: 200px;
// 		box-sizing: border-box;
// 	}
// 	.table-wrapper .pinned {
// 		max-width: 201px;
// 		min-width: 201px;
// 		width: 201px;
// 	}
// 	.table-wrapper .scrollable {
// 		padding-left: 200px;
// 	}
// 	.table-wrapper .scrollable > table {
// 		margin-left: -200px;
// 	}
// 	td {
// 		min-width: 70px;
// 	}
// }
// .scrollable {
// 	-webkit-overflow-scrolling: touch;
// }
// .split_table_area table.split_table th:first-child,
// .split_table_area table.split_table td:first-child,
// .split_table_area table.split_table td:first-child,
// .split_table_area table.split_table.pinned td {
// 	display: table-cell;
// }


.archive_list {
	li {
		padding-left: 1em;
		&::before {
			left: .5em;
		}
	}
}

.image_list {
	a {
		display: block;
	}
	img {
		// @extend %shadow;
		display: block;
		// border-radius: $border_radius;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	li {
		margin-bottom: 30px;
	}
}

figure {
	img {
		display: block;
		max-width: 100%;
	}
	figcaption {
		padding-top: .3em;;
		font-size: $fontsize15;
		color: #705322;
	}
}

.banner {
	@include on_opacity;
	display: inline-block;

	img {
		display: block;
		max-width: 100%;
	}
}

.inner_float_left {
	float: left;
	margin-right: 30px;
	margin-bottom: 30px;
}
.inner_float_right {
	float: right;
	margin-left: 30px;
	margin-bottom: 30px;
}


.faq_list {
	> li {
		border-bottom: 1px solid #d3d3d3;
		padding-bottom: 1.2em;

		&:not(:first-child) {
			padding-top: 1.2em;
		}
	}
}
.faq {
	&__q {
		// // @include icon-sprite2($icon-faq-q, -.05em);
		color: palette('orange');
		font-size: $fontsize16;
		padding-left: 40px;
		padding-bottom: .7em;
	}
	&__a {
		// // @include icon-sprite2($icon-faq-a, -.05em);
		font-size: $fontsize15;
		padding-left: 40px;
	}
}

.signature {
	text-align: right;
}


%shadow,
.shadow {
	box-shadow: 1px 1px 2.5px rgba(#000, .3);
}


.wpnews {
	@include clearfix;
	&__head {
		margin-bottom: 50px;
		h3 a {
			text-decoration: none;
			color: #000;
			&:hover {
				color: palette('link');
			}
		}
	}
	&__attr {
		text-align: center;
		> * {
			display: inline-block;
			font-size: $fontsize15;
		}
		.date {
			&::after {
				content: '/';
				display: inline;
				padding: 0 .7em;
			}
		}
		.category {
			color: #888;
		}
	}
	&__content {
		width: 800px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 90px;
		font-size: $fontsize15;
		line-height: 1.7;

		img {
			max-width: 100%;
			height: auto;
		}
	}

	// em {
	// 	font-weight: normal;
	// 	font-style: italic;
	// }
	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	blockquote {
		padding: 10px;
		border-left: 5px solid #59A676;
		background-color: #F7F7F7;
		margin-bottom: 1em;
	}
}

.gmap {
	height: 400px;
	margin-bottom: 20px;
}

%marker,
.marker {
	background: linear-gradient(transparent 60%, #{palette('marker')} 60%);
}

.image_right {
	line-height: 1;
	small {
		font-size: $fontsize10;
		line-height: 1;
		display: block;
	}
}

.cell {
	margin-right: 1em;
}

.cell2 {
	margin-right: 0.5em;
}

.scell {
	margin-right: 1em;
}

.link,
%link {
	@include icon-sprite2($icon-arrow-right-green2, $top: 7px, $before: 'before', $is_block: true, $margin-right: 0);
	padding-left: 10px;
	line-height: 1.4;
	color: palette('color');
	&:hover {
		// text-decoration: underline;
		color: palette('link');
	}
}

.link {
	&-down {
		&::before {
			transform: rotate(90deg);
		}
	}
}

.link2 {
	// // @include icon-sprite2($icon-arrow-right-black);
	color: palette('link');
	padding-left: 11px;

	&:hover {
		text-decoration: underline;
	}
}

.box {
	text-align: left;
	display: inline-block;
	border: 1px #ccc solid;
	padding: 0.5em 1.5em;
	border-radius:  10px;
	line-height: 1.8;
	margin: 0.5em auto 0;
	background-color: #fff;
}

.panel {
	background-color: lighten(palette('green', '3'), 25%);
	border: 1px solid lighten(palette('green', '3'), 15%);
	padding: 40px 20px;
	// border-radius: 10px;
}

.comming_soon {
	pointer-events: none !important;
	&:hover {
		opacity: 1 !important;
	}
}

.comming_soon_hide {
	pointer-events: none !important;
	visibility: hidden !important;
}

